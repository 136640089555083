import React from "react";

import { Heading } from "../components/heading";

const FourOhFour = () => {
  return (
    <>
      <Heading>
        Human sacrifice, dogs and cats living together... mass hysteria!
      </Heading>
    </>
  );
};

export default FourOhFour;
